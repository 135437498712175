.elevated-container.addPurchase-container {
    margin: 96px auto;
    max-width: 960px;
    min-width: 320px;
    border-radius: 8px;
}

@media (max-width: 900px) {
    .elevated-container.addPurchase-container {
        margin: 0px;
        padding: 32px 16px;
        border: none;
        box-shadow: none;
        background: none;
    }
}

.add-purchase-form {
    display: flex;
    flex-direction: column;
    gap: 32px;
    text-align: center;
    align-items: center;
}

.holding-uploader {
    text-align: center;
}

.symbol-autocomplete {
    text-transform: capitalize;
}