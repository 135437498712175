.tableContainer {
    padding: 0;
    width: 100%;
    margin: 0;
}

.profit {
    color: #10b983 !important;
}

.loss {
    color: #f35631 !important;
}

.profitDark {
    color: #00bf83 !important;
}

.lossDark {
    color: #d2615c !important;
}