html {
  height: 100%;
}

:root {
  color-scheme: light;
  --background-color: #F0EFF3;
  --background-color-2: #F0EFF3;
  --container-box-shadow: rgba(17, 12, 46, 0.15) 0px 0px 50px 0px;
  --table-header-hover: #f2f2f2;
  --magnyfire-text-primary: rgba(0, 0, 0, 0.85);
  --magnyfire-border: 1px solid rgba(0, 0, 0, 0.12);
  --magnyfire-border-color-1: rgb(200, 200, 220);
  --magnyfire-background-primary: rgb(30, 125, 215);
  --magnyfire-background-focused: rgb(25, 115, 200);
  --magnyfire-background-disabled: rgba(21, 101, 192, 0.25);
  --magnyfire-text-disabled: rgba(255, 255, 255, 0.9);
  --magnyfire-container-background: #fff;
  --magnyfire-container-shadow: 0 8px 12px 0 rgba(0, 0, 0, 0.12);
}

[data-mui-color-scheme="dark"] {
  color-scheme: dark;
  --background-color: rgba(16, 16, 16, 1);
  --background-color-2: rgb(25, 25, 25);
  --container-box-shadow: rgba(200 200 200 / 15%) 0px 0px 50px 0px;
  --table-header-hover: #1e1e1e;
  --magnyfire-text-primary: rgba(255, 255, 255, 0.85);
  --magnyfire-border-color-1: rgb(50, 55, 70);
  --magnyfire-border: 1px solid rgba(255, 255, 255, 0.12);
  --magnyfire-background-primary: rgb(30, 125, 215);
  --magnyfire-background-focused: rgb(25, 115, 200);
  --magnyfire-background-disabled: rgba(21, 101, 192, 0.25);
  --magnyfire-text-disabled: rgba(255, 255, 255, 0.3);
  --magnyfire-container-background: linear-gradient(35deg, rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0));
  --magnyfire-container-shadow: 0 8px 32px 0 rgba(0, 0, 0, 0.4);
}

body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  font-family: "Inter", -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  letter-spacing: 0;
  color: var(--mui-palette-text-primary);
  line-height: 1.7em;
  background-color: var(--background-color);
}

h1, h2, h3, h4, h5, h6 {
  font-family: "Merriweather", "Inter", -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Helvetica Neue',
    sans-serif !important;
  margin: 0 0 16px 0;
  color: var(--magnyfire-text-primary)
}

h1 {
  font-size: 3em;
  margin: 0 0 32px 0;
}

h2 {
  font-size: 2.5em;
  margin: 0 0 32px 0;
}

h3 {
  font-size: 2em;
}

h4 {
  font-size: 1.5em;
}

@media (max-width: 600px) {
  h1 {
    font-size: 2em;
  }

  h2 {
    font-size: 1.8em;
  }

  h3 {
    font-size: 1.5em;
  }

  h4 {
    font-size: 1.2em;
  }
}

#root {
  height: 100%;
}

code {
  font-family: "Inter", source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace !important;
}