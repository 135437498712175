.form-item {
    margin-bottom: 32px !important;
}

@media (max-width: 900px) {
    .elevated-container.login-container {
        margin: 0px;
        padding: 32px 16px;
        border: none;
        box-shadow: none;
        background: none;
        max-width: none;
    }
}