.holdingInfo-container {
    width: 100%;
    background-color: var(--mui-palette-background-default);
    color: var(--mui-palette-text-primary);
    z-index: 2;
}

.holdingInfo {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    max-width: 720px;
    margin: auto;
    font-size: 0.8rem;
}

.holdingInfoItem {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    padding: 8px;
    flex-shrink: 0;
}