.input-group {
    margin: 8px;
}

.sipCalculator-result-container {
    display: flex;
    flex-direction: column;
    min-width: 240px;
    max-width: 700px;
}

.sipCalculator-result-row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
}

.sipCalculator-result-amount {
    text-align: right;
    max-width: 70%;
}