.holdings-summary {
    background-color: #000;
    color: #939393;
    padding-top: 16px;
}

.pnl-top-banner {
    background-color: #13161C;
    margin: 8px 20px 16px 20px;
    padding: 16px;
    border-radius: 6px;
}

.pnl-banner-row.price {
    font-size: 1.3rem;
    padding: 8px 0 0 0;
    margin: 0;
}

.pnl-banner-row .price-value {
    display: flex;
    align-items: center;
}

.pnl-banner-percent {
    background-color: #1B2A20;
    border-radius: 24px;
    padding: 0 8px;
    margin-left: 8px;
    font-size: 1rem;
}

.pnl-banner-percent.profit {
    background-color: #1B2A20;
}

.pnl-banner-percent.loss {
    background-color: #2a1b1b;

}

.pnl-banner-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 8px;
}

.pnl-banner-row.main-text {
    font-size: 1.3rem;
    color: #d9d9d9;
    padding-bottom: 16px;
    border-bottom: 1px solid #313131;
}

.holding-summary-day-pnl-strip .percentage {
    font-size: 0.8rem;
    padding-left: 8px;
}

.holding-summary-day-pnl-strip {
    position: sticky;
    bottom: 0;
    display: flex;
    justify-content: space-between;
    padding: 12px 8px;
    background-color: #13161C;
}